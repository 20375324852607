<template>
    <div class="w-full">
        <div class="w-full flex justify-between mb-5 text-jet">
            <div class="flex justify-between items-center gap-4">
                <icon :icon-name="typeIcon" class="width" size="xs"/>
                <p class="font-bold text-lg">{{type}}</p>
            </div>

             <div class="flex justify-between items-center text-sm font-semibold">
                <div class="flex justify-between items-center gap-1 pr-4 border-r border-solid border-borderGrey">
                    <icon icon-name="home-storage" size="xs" class="icon-width"/>
                    <p>Total Storage: {{totalGB}}</p>
                </div>
                <div class="flex justify-between items-center gap-1 pl-4">
                    <CircularProgressBar :percentage="percentage" width="24" height="24"/>
                    <p>{{percentage.toFixed(2)}}% of Total Space Allocated</p>
                </div>
            </div>
        </div>

        <div v-if="folders.length > 1" class="w-full card-container gap-2">
            <Card v-for="(folder, index) in folders" :key="index"
                :style="{background: `${index === 0 ? `#F7F7FF` : `#FFFFFF`}`}"
                class="card px-2 py-3 text-jet text-sm flex flex-col justify-between font-semibold"
            >
                <div class="w-full">
                    <p class="w-full mx-auto text-left text-xs font-semibold uppercase text-romanSilver">{{folder.name}}</p>
                    <p class="mtop text-sm">Total Storage: {{ folder.maxStorage ? folder.maxStorage : 0}} GB</p>
                </div>

                <div>
                    <div class="w-full h5 bg-cultured rounded-md mb-2 mt-3">
                        <div class="h-full bg-blueCrayola rounded-md"
                            :style="`width: ${folder.folderSize / 10485760 * 100 > 100 || folder.folderSize / 10485760 * 100}%`"
                        >
                        </div>
                    </div>
                    <p class="mtop text-sm font-normal">Using 
                        <span>{{ folder.folderSize ? kbToSize(folder.folderSize) : 0}}</span> of 
                        <span>{{ folder.allocatedSpace ? folder.allocatedSpace : 0 }} GB</span>
                    </p>
                </div>
            </Card>
        </div>

        <EmptyState v-if="folders.length === 0" />
    </div>
</template>
  
<script>

export default {
    name: "DocsStorage",
    components: {
        CircularProgressBar: () => import('./CircularProgressBar'),
        Card: () => import('@/components/Card'),
        EmptyState: () => import('./EmptyState'),
    },
    props: {
        type: String,
        typeIcon: String,
        totalGB: String || Number,
        storageUseage: String || Number,
        percentage: Number,
        folders: []
    },
    data() {
        return {
        };
    },
    methods: {
        kbToSize(kb) {
            const sizes = ['KB', 'MB', 'GB', 'TB', 'PB'];
            if (kb === 0) return '0 KB';
            if (kb < 1) return `${(kb * 1024).toFixed(1)} KB`; // Converted small KB to Bytes
                const i = parseInt(Math.floor(Math.log(kb) / Math.log(1024)), 10);
                const sizeIndex = Math.min(i, sizes.length - 1);
            if (sizeIndex === 0) return `${kb.toFixed(1)} ${sizes[sizeIndex]}`;
            return `${(kb / (1024 ** sizeIndex)).toFixed(1)} ${sizes[sizeIndex]}`;
        }
    },
};
</script>

<style scoped>
.card-container{
    display: grid !important;
    grid-template-columns: repeat(4, 1fr) !important;
}

.card{
    border: 1px solid #C2C7D6;
    border-radius: 12px !important;
}

.h5{
    height: 5px;
}

.width{
    width: 28px !important;
    height: 28px !important;
}

.icon-width{
    width: 22px !important;
    height: 22px !important;
}

.mtop{
    margin-top: 2px;
}
</style>